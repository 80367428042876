interface AppConfig {
  stytchKey: string;
}

let appConfig: AppConfig = {
  stytchKey: "public-token-test-e17c4fdf-87fb-4a2b-b4f7-8a6fa09aeb8d",
};

const mode = import.meta.env.MODE;

if (mode === "development") {
  appConfig = {
    stytchKey: "public-token-test-e17c4fdf-87fb-4a2b-b4f7-8a6fa09aeb8d",
  };
} else if (mode === "production") {
  appConfig = {
    stytchKey: "public-token-live-38b25091-8fc9-4d55-9232-bb3e07ae8117",
    // stytchKey: 'public-token-test-e17c4fdf-87fb-4a2b-b4f7-8a6fa09aeb8d',
  };
} else if (mode === "staging") {
  appConfig = {
    stytchKey: "public-token-live-38b25091-8fc9-4d55-9232-bb3e07ae8117",
    // stytchKey: 'public-token-test-e17c4fdf-87fb-4a2b-b4f7-8a6fa09aeb8d',
  };
} else if (mode === "dev") {
  appConfig = {
    stytchKey: "public-token-test-e17c4fdf-87fb-4a2b-b4f7-8a6fa09aeb8d",
  };
}

export default appConfig;
