import { ROUTES } from "@/shared/constants/routes";
import { addNotification } from "@/shared/states/notification";
import { NavigateFunction } from "react-router-dom";

export const handleSocketResponse = (
  response: any,
  navigate?: NavigateFunction,
) => {
  if (response.status === false) {
    addNotification({
      message:
        typeof (response as { message?: string })?.message === "string"
          ? (response as { message: string }).message
          : "Error in processing the file",
      type: "error",
    });
    navigate?.(`/${ROUTES.AGENT}`);
  }
};
