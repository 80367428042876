import React from "react";
import ReactDOM from "react-dom/client";
import App from "./modules/app/components/App.tsx";
import "./index.css";
import { NextUIProvider } from "@nextui-org/react";
import { StytchB2BProvider } from "@stytch/react/b2b";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StrictMode } from "react";
import { initPostHog } from "./infra/posthog/init.ts";
import { stytch } from "./infra/stytch/index.ts";
import { NotificationsProvider } from "./shared/components/custom/notification/provider.tsx";

import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
  BugsnagPluginReactResult,
} from "@bugsnag/plugin-react";

export const queryClient = new QueryClient();

let ErrorBoundary: BugsnagErrorBoundary | undefined = undefined;
let bugsnagPlugin: BugsnagPluginReactResult | undefined;
let needBoundary = false;

// only enable bugsnag and posthog in production
export const shouldEnableInsights = () => {
  const hostname = window.location.hostname;
  const whitelist = ["zania.app", "www.zania.app"];

  return whitelist.includes(hostname);
};

if (shouldEnableInsights()) {
  Bugsnag.start({
    apiKey: "7c862cdc6a93291d2c547aedea58f346",
    plugins: [new BugsnagPluginReact()],
  });
  BugsnagPerformance.start({ apiKey: "7c862cdc6a93291d2c547aedea58f346" });
  bugsnagPlugin = Bugsnag.getPlugin("react");
  ErrorBoundary = bugsnagPlugin?.createErrorBoundary
    ? bugsnagPlugin.createErrorBoundary(React)
    : undefined;
  needBoundary = true;

  initPostHog();
} else {
  ErrorBoundary = undefined;
  bugsnagPlugin = undefined;
  needBoundary = false;
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <StytchB2BProvider stytch={stytch}>
        <NextUIProvider>
          <NotificationsProvider maxNotifications={2} />
          {needBoundary && ErrorBoundary ? (
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          ) : (
            <App />
          )}
        </NextUIProvider>
      </StytchB2BProvider>
    </QueryClientProvider>
  </StrictMode>,
);
