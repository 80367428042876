import { create } from "zustand";
import { StytchClientType } from "../types/stytch";

interface StytchState {
  stytch: StytchClientType;
}

interface StytchStateAction {
  setStytch: (stytch: StytchClientType) => void;
}

type StytchStore = StytchState & {
  actions: StytchStateAction;
};

const useStytchStore = create<StytchStore>((set) => ({
  stytch: {} as StytchClientType,
  actions: {
    setStytch: (stytch) => set({ stytch }),
  },
}));

export const useStytch = () => useStytchStore((state) => state.stytch);
export const useStytchActions = () => useStytchStore((state) => state.actions);
export const setStytch = useStytchStore.getState().actions.setStytch;
