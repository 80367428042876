import { ROUTES } from "@/shared/constants/routes";
import { importLazy } from "@/shared/utils/import-lazy";
import { RouteObject } from "react-router-dom";

export const SettingsRoutes: RouteObject[] = [
  {
    path: ROUTES.SETTINGS,
    lazy: importLazy(() => import("@/modules/settings/components/layout")),
    children: [
      {
        index: true,
        lazy: importLazy(() => import("@/modules/settings/components/main")),
      },
    ],
  },
];
