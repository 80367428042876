import {
  AGENT_TYPES,
  AgentData,
  AgentSpecificSubTypes,
} from "../types/index.ts";

export function approveAll<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
>(agentData: AgentData<T, S>) {
  agentData.mainData.approvedIds = agentData.mainData.reviewResponseIds || [];
}

export function updateApprovedIds<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
>(agentData: AgentData<T, S>, id: string | string[], isApproved: boolean) {
  const ids = Array.isArray(id) ? id : [id];
  if (isApproved) {
    agentData.mainData.approvedIds.push(...ids);
  } else {
    agentData.mainData.approvedIds = agentData.mainData.approvedIds.filter(
      (approvedId) => !ids.includes(approvedId),
    );
  }
}

export function setNextUnapproved<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
>(agentData: AgentData<T, S>): boolean | undefined {
  const selectedId = agentData.mainData.selectedId;
  const reviewResponseIds = agentData.mainData.reviewResponseIds;

  if (!selectedId) return;
  if (!reviewResponseIds) return;

  const currentIdx =
    agentData.mainData.reviewResponseIds?.indexOf(selectedId) || -1;

  let nextUnapproved = findNextUnapproved<T, S>(
    agentData,
    currentIdx + 1,
    reviewResponseIds.length,
  );

  if (!nextUnapproved) {
    nextUnapproved = findNextUnapproved<T, S>(agentData, 0, currentIdx);
  }

  if (nextUnapproved) {
    agentData.mainData.selectedId = nextUnapproved;
    return true;
  }

  return false;
}

function findNextUnapproved<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
>(agentData: AgentData<T, S>, startIdx: number, endIdx: number): string | null {
  const reviewResponseIds = agentData.mainData.reviewResponseIds;
  if (!reviewResponseIds) return null;

  for (let i = startIdx; i < endIdx; i++) {
    const responseId = reviewResponseIds[i];
    if (!agentData.mainData.approvedIds.includes(responseId)) {
      return responseId;
    }
  }

  return null;
}
