import { ReactNode } from "react";
import { create } from "zustand";
import { Notification } from "../types/notification";

interface NotificationState {
  notifications: Record<string, Notification>;
  notificationKeys: string[];
}

interface NotificationActions {
  addNotification: (
    notification: Partial<Notification> & {
      message?: React.ReactNode | string;
    },
  ) => string;
  hideNotification: (id: string) => void;
  removeNotification: (id: string) => void;
  setState: (state: Partial<NotificationState>) => void;
}

type NotificationStore = NotificationState & {
  actions: NotificationActions;
};

const useNotificationStore = create<NotificationStore>((set, get) => ({
  notifications: {},
  notificationKeys: [],
  actions: {
    setState: (state) =>
      set({
        ...get(),
        ...state,
      }),
    addNotification: ({ id, ...content }) => {
      const realId = id ? id : Date.now().toString();
      const { notifications, notificationKeys } = get();

      const newNotifications = {
        ...notifications,
        [realId]: {
          id: realId,
          open: true,
          message: content.message,
          type: content.type ?? "success",
          title: content.title ?? "",
          autoHideDuration: content.autoHideDuration,
        },
      };

      const newNotificationKeys = [...notificationKeys, realId];

      set({
        notifications: newNotifications,
        notificationKeys: newNotificationKeys,
      });
      return realId;
    },
    hideNotification: (id) => {
      const { notifications } = get();
      const notification = notifications[id];
      if (notification) {
        const newNotifications = {
          ...notifications,
          [id]: { ...notification, open: false },
        };
        set({ notifications: newNotifications });
      }
    },
    removeNotification: (id) => {
      const { notifications, notificationKeys } = get();
      const newNotifications = { ...notifications };
      delete newNotifications[id];
      const newNotificationKeys = notificationKeys.filter((key) => key !== id);
      set({
        notifications: newNotifications,
        notificationKeys: newNotificationKeys,
      });
    },
  },
}));

export const addNotification =
  useNotificationStore.getState().actions.addNotification;
export const hideNotification =
  useNotificationStore.getState().actions.hideNotification;
export const removeNotification =
  useNotificationStore.getState().actions.removeNotification;

export const useNotificationAction = () =>
  useNotificationStore((state) => state.actions);
export const useNotificationState = () =>
  useNotificationStore((state) => ({
    notifications: state.notifications,
    notificationKeys: state.notificationKeys,
  }));
export const getNotification = (id: string) =>
  useNotificationStore.getState().notifications[id];
