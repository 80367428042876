import { ReviewResponseType } from "../types/index.ts";
import { ResponseSchemaVersion } from "../types/risk-and-gap.ts";

export const getRenderType = (key: string, version?: ResponseSchemaVersion) => {
  if (version === "v2") {
    if (
      [
        "gaps",
        "recommendations",
        "improvement_opportunities",
        "improvement_recommendations",
      ].includes(key)
    ) {
      return ReviewResponseType.LIST_OF_TEXT_WITH_SOURCE;
    }

    if (["observations"].includes(key)) {
      return ReviewResponseType.TEXT_WITH_SOURCE;
    }
  }

  if (["risk_value"].includes(key)) {
    return ReviewResponseType.NUMBER;
  }

  if (["justification"].includes(key)) {
    return ReviewResponseType.TEXT_DISPLAY;
  }

  if (["compliance_status", "compliant"].includes(key)) {
    return ReviewResponseType.COMPLIANCE_STATUS;
  }

  if (["sources"].includes(key)) {
    return ReviewResponseType.SOURCE;
  }

  return ReviewResponseType.TEXT;
};
