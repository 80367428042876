import { post } from "@/infra/rest";

interface SignedUrlResponse {
  success: boolean;
  message: string;
  data: {
    signed_urls: string[];
  };
}

interface SignedUrlRequest {
  file_names?: string[];
  stale_urls?: string[];
  max_age?: number;
  folder?: string;
}

export const getSignedUrl = async ({
  file_names,
  max_age,
  folder,
  stale_urls,
}: SignedUrlRequest): Promise<string[]> => {
  const data = await post<SignedUrlRequest, SignedUrlResponse>({
    url: "/v0/files/upload/signed-url",
    data: {
      file_names,
      max_age: max_age || 3600,
      folder,
      stale_urls,
    },
  });
  return data.data.signed_urls;
};
