import { create } from "zustand";
import { LOGIN_STEP } from "../types";
import { resetLoginDetails, setLoginStep } from "./login";

interface AuthState {
  isAuthenticated: boolean;
  isAuthenticationInProgress: boolean;
}

interface AuthActions {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setIsAuthenticatingInProgress: (isAuthenticationInProgress: boolean) => void;
  setAuth: (auth: AuthState) => void;
  resetAuth: () => void;
  logout: () => void;
}

type AuthStore = AuthState & {
  actions: AuthActions;
};

const initialState: AuthState = {
  isAuthenticationInProgress: true,
  isAuthenticated: false,
};

const useAuthStore = create<AuthStore>((set) => ({
  ...initialState,
  actions: {
    setIsAuthenticated: (isAuthenticated) =>
      set((state) => {
        if (isAuthenticated) resetLoginDetails();
        return { ...state, isAuthenticated };
      }),
    setIsAuthenticatingInProgress: (isAuthenticationInProgress) =>
      set((state) => ({ ...state, isAuthenticationInProgress })),
    setAuth: (auth) => set(auth),
    resetAuth: () => set(initialState),
    logout: () => {
      setLoginStep(LOGIN_STEP.MAIN);
      set({
        isAuthenticated: false,
        isAuthenticationInProgress: false,
      });
    },
  },
}));

export const useIsAuthenticated = () =>
  useAuthStore((state) => state.isAuthenticated);
export const useIsAuthenticationInProgress = () =>
  useAuthStore((state) => state.isAuthenticationInProgress);
export const useAuthActions = () => useAuthStore((state) => state.actions);
export const useAuth = () => useAuthStore();
export const setAuth = useAuthStore.getState().actions.setAuth;
export const resetAuth = useAuthStore.getState().actions.resetAuth;
