import { Draft } from "mutative";
import { AgentStore, useAgentStore } from ".";
import { AGENT_TYPES, AgentData, QuestionnaireTypes } from "../types/index.ts";
import {
  AnswerColumnMappingData,
  ColumnMappingData,
  MappingData,
  MappingType,
  Merges,
  QuestionnaireFileData,
  QuestionnaireFiller,
  SheetStatus,
} from "../types/questionnaire.ts";
import { getJSONFromSheet } from "../utils/excel-parser";

type SetSheetDataArg = {
  sheetName: string;
  sheetData: {
    data: string[][];
    merges: Merges;
  };
};
interface UpdateSheetMapping {
  sheetName: string;
  action: "add" | "remove";
  isOnlySingleAllowed?: boolean;
}

interface EditAnswerMapping extends Omit<UpdateSheetMapping, "action"> {
  type: MappingType.ANSWERS;
  value: AnswerColumnMappingData;
  oldColumnIndex: number;
  action: "edit";
}

interface AddAnswerMapping extends Omit<UpdateSheetMapping, "action"> {
  type: MappingType.ANSWERS;
  action: "add";
  value: AnswerColumnMappingData | AnswerColumnMappingData[];
  replace?: boolean;
}

interface RemoveAnswerMapping extends Omit<UpdateSheetMapping, "action"> {
  type: MappingType.ANSWERS;
  action: "remove";
  value: AnswerColumnMappingData;
}

type UpdateAnswerMapping =
  | EditAnswerMapping
  | AddAnswerMapping
  | RemoveAnswerMapping;

interface UpdateMapping extends UpdateSheetMapping {
  type:
    | MappingType.COMMENTS
    | MappingType.QUESTIONS
    | MappingType.QUESTION_TYPE;
  value: ColumnMappingData;
}

interface QuestionRowAddMapping extends Omit<UpdateSheetMapping, "action"> {
  type: MappingType.QUESTION_START_ROW;
  value: number;
  action: "add";
}

interface QuestionRowRemoveMapping extends Omit<UpdateSheetMapping, "action"> {
  type: MappingType.QUESTION_START_ROW;
  action: "remove";
}

type QuestionRowMapping = QuestionRowAddMapping | QuestionRowRemoveMapping;

type UpdateSheetMappingArg =
  | UpdateAnswerMapping
  | UpdateMapping
  | QuestionRowMapping;

type SetSheetMappingDataArg = {
  sheetName: string;
  mappingData: MappingData;
};

export type QuestionnaireActions = {
  setSelectedSheet: (agentId: string, sheetName: string) => Promise<void>;
  setFileDetails: (agentId: string, fileDetails: QuestionnaireFileData) => void;
  setSheetData: (agentId: string, sheetData: SetSheetDataArg) => void;
  updateSheetMapping: (agentId: string, data: UpdateSheetMappingArg) => void;
  setSheetMappingData: (agentId: string, data: SetSheetMappingDataArg) => void;
  setSheetStatus: (
    agentId: string,
    sheetName: string,
    status: SheetStatus | undefined,
  ) => void;
  setQuestionnaireFiller: (
    agentId: string,
    data: QuestionnaireFiller[],
  ) => void;
};

export const createQuestionnaireActions = (
  set: (
    nextStateOrUpdater:
      | AgentStore
      | Partial<AgentStore>
      | ((state: Draft<AgentStore>) => void),
    shouldReplace?: boolean | undefined,
  ) => void,
  get: () => AgentStore,
): QuestionnaireActions => ({
  setSelectedSheet: async (agentId, sheetName) => {
    // First, try to update the state synchronously
    let needsDataFetch = false;

    set((state) => {
      const agentData = state.agentDataMap.get(agentId) as AgentData<
        AGENT_TYPES.QUESTIONNAIRE,
        QuestionnaireTypes.QA
      >;
      if (!agentData) return state;

      const sheetData =
        agentData.mainData.questionnaireFileData.sheetData[sheetName];
      if (sheetData) {
        // If sheet data is already present, just update the selected sheet
        agentData.mainData.selectedSheet = sheetName;
        return state;
      }

      // If sheet data is not present, we'll need to fetch it
      needsDataFetch = true;
      return state;
    });

    // If we don't need to fetch data, we're done
    if (!needsDataFetch) return;

    // If we need to fetch data, do it asynchronously
    const agentData = get?.()?.agentDataMap.get(agentId) as AgentData<
      AGENT_TYPES.QUESTIONNAIRE,
      QuestionnaireTypes.QA
    >;
    if (!agentData || !agentData.mainData.questionnaireFileData.workbook)
      return;

    const { data, merges } = await getJSONFromSheet(
      agentData.mainData.questionnaireFileData.workbook,
      sheetName,
    );

    // Update the state with the fetched data
    set((state) => {
      const updatedAgentData = state.agentDataMap.get(agentId) as AgentData<
        AGENT_TYPES.QUESTIONNAIRE,
        QuestionnaireTypes.QA
      >;
      if (!updatedAgentData) return state;

      updatedAgentData.mainData.selectedSheet = sheetName;
      updatedAgentData.mainData.questionnaireFileData.sheetData[sheetName] = {
        data,
        merges,
      };

      return state;
    });
  },
  setFileDetails: (agentId, fileDetails) =>
    set((state) => {
      const agentData = state.agentDataMap.get(agentId) as AgentData<
        AGENT_TYPES.QUESTIONNAIRE,
        QuestionnaireTypes.QA
      >;
      if (agentData) {
        agentData.mainData.questionnaireFileData = fileDetails;
      }
    }),
  setSheetData: (agentId, sheetData) =>
    set((state) => {
      const agentData = state.agentDataMap.get(agentId) as AgentData<
        AGENT_TYPES.QUESTIONNAIRE,
        QuestionnaireTypes.QA
      >;
      if (agentData) {
        agentData.mainData.questionnaireFileData.sheetData[
          sheetData.sheetName
        ] = sheetData.sheetData;
      }
    }),
  setSheetMappingData: (agentId, data) =>
    set((state) => {
      const agentData = state.agentDataMap.get(agentId) as AgentData<
        AGENT_TYPES.QUESTIONNAIRE,
        QuestionnaireTypes.QA
      >;
      if (agentData) {
        agentData.mainData.questionnaireMapping[data.sheetName] =
          data.mappingData;
      }
    }),
  setSheetStatus: (agentId, sheetName, status) =>
    set((state) => {
      const agentData = state.agentDataMap.get(agentId) as AgentData<
        AGENT_TYPES.QUESTIONNAIRE,
        QuestionnaireTypes.QA
      >;
      if (agentData) {
        agentData.mainData.questionnaireMapping[sheetName].status = status;
      }
    }),
  updateSheetMapping: (agentId, data) => {
    set((state) => {
      const agentData = state.agentDataMap.get(agentId) as AgentData<
        AGENT_TYPES.QUESTIONNAIRE,
        QuestionnaireTypes.QA
      >;

      const { sheetName, type, action } = data;
      const removeDuplicate = (columnIndex: number) => {
        const mapping = agentData.mainData.questionnaireMapping[sheetName];
        Object.entries(mapping).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            agentData.mainData.questionnaireMapping[sheetName] = {
              ...agentData.mainData.questionnaireMapping[sheetName],
              [key]: value.filter((item) => item.columnIndex !== columnIndex),
            };
          }
        });
      };

      switch (action) {
        case "add": {
          switch (type) {
            case MappingType.QUESTIONS:
            case MappingType.COMMENTS:
            case MappingType.QUESTION_TYPE:
              removeDuplicate(data.value.columnIndex);
              agentData.mainData.questionnaireMapping[sheetName][type] = [
                data.value,
              ];
              break;
            case MappingType.QUESTION_START_ROW:
              agentData.mainData.questionnaireMapping[
                sheetName
              ].questionStartRow = data.value;
              break;
            case MappingType.ANSWERS:
              if (Array.isArray(data.value)) {
                data.value.forEach((value) => {
                  removeDuplicate(value.columnIndex);
                });
              } else removeDuplicate(data.value.columnIndex);

              if (data.replace) {
                agentData.mainData.questionnaireMapping[sheetName][type] =
                  Array.isArray(data.value) ? data.value : [data.value];
              } else {
                agentData.mainData.questionnaireMapping[sheetName][type] = [
                  ...agentData.mainData.questionnaireMapping[sheetName][type],
                  ...(Array.isArray(data.value) ? data.value : [data.value]),
                ];
              }
              break;
          }
          break;
        }
        case "remove": {
          switch (type) {
            case MappingType.QUESTIONS:
            case MappingType.COMMENTS:
            case MappingType.QUESTION_TYPE:
              agentData.mainData.questionnaireMapping[sheetName][type] =
                agentData.mainData.questionnaireMapping[sheetName][type].filter(
                  (item) => item.columnIndex !== data.value.columnIndex,
                );
              break;
            case MappingType.QUESTION_START_ROW:
              agentData.mainData.questionnaireMapping[
                sheetName
              ].questionStartRow = null;
              break;
            case MappingType.ANSWERS:
              agentData.mainData.questionnaireMapping[sheetName][type] =
                agentData.mainData.questionnaireMapping[sheetName][type].filter(
                  (item) => item.columnIndex !== data.value.columnIndex,
                );
              break;
          }
          break;
        }
        case "edit": {
          switch (type) {
            case MappingType.ANSWERS:
              agentData.mainData.questionnaireMapping[sheetName][type] =
                agentData.mainData.questionnaireMapping[sheetName][type].map(
                  (item) =>
                    item.columnIndex === data.oldColumnIndex
                      ? data.value
                      : item,
                );
              break;
          }
        }
      }
    });
  },
  setQuestionnaireFiller: (agentId, data) =>
    set((state) => {
      const agentData = state.agentDataMap.get(agentId) as AgentData<
        AGENT_TYPES.QUESTIONNAIRE,
        QuestionnaireTypes.QA
      >;
      if (agentData) {
        agentData.mainData.questionnaireFiller = data;
      }
    }),
});

export const useQuestionnaireFile = (id: string) =>
  useAgentStore(
    (state) =>
      (
        state.agentDataMap.get(id) as AgentData<
          AGENT_TYPES.QUESTIONNAIRE,
          QuestionnaireTypes.QA
        >
      )?.mainData.questionnaireFileData,
  );

export const useSelectedSheet = (id: string) =>
  useAgentStore(
    (state) =>
      (
        state.agentDataMap.get(id) as AgentData<
          AGENT_TYPES.QUESTIONNAIRE,
          QuestionnaireTypes.QA
        >
      )?.mainData.selectedSheet,
  );

export const useSelectedSheetData = (agentId: string) => {
  const selectedSheetId = useSelectedSheet(agentId);
  const sheetData = useAgentStore(
    (state) =>
      (
        state.agentDataMap.get(agentId) as AgentData<
          AGENT_TYPES.QUESTIONNAIRE,
          QuestionnaireTypes.QA
        >
      )?.mainData.questionnaireFileData.sheetData[selectedSheetId],
  );
  return { sheetData, selectedSheetId };
};

export const useSheetMapping = (agentId: string, sheetName: string) =>
  useAgentStore(
    (state) =>
      (
        state.agentDataMap.get(agentId) as AgentData<
          AGENT_TYPES.QUESTIONNAIRE,
          QuestionnaireTypes.QA
        >
      )?.mainData.questionnaireMapping[sheetName],
  );

export const getSheetStatus = (agentId: string, sheetName: string) =>
  (
    useAgentStore.getState().agentDataMap.get(agentId) as AgentData<
      AGENT_TYPES.QUESTIONNAIRE,
      QuestionnaireTypes.QA
    >
  )?.mainData.questionnaireMapping?.[sheetName]?.status;

export const useSelectedSheetMapping = (agentId: string) => {
  const selectedSheetId = useSelectedSheet(agentId);
  return useAgentStore(
    (state) =>
      (
        state.agentDataMap.get(agentId) as AgentData<
          AGENT_TYPES.QUESTIONNAIRE,
          QuestionnaireTypes.QA
        >
      )?.mainData.questionnaireMapping[selectedSheetId],
  );
};

export const isReadyToGenerateResponse = (id: string) => {
  const agentData = useAgentStore.getState().agentDataMap.get(id) as AgentData<
    AGENT_TYPES.QUESTIONNAIRE,
    QuestionnaireTypes.QA
  >;
  const { questionnaireMapping } = agentData.mainData;

  let allSheetsIgnored = true;

  for (const mappingType in questionnaireMapping) {
    const mapping = questionnaireMapping[mappingType];

    if (mapping.status !== SheetStatus.IGNORED) {
      allSheetsIgnored = false;

      if (
        !mapping.questions ||
        mapping.questions.length === 0 ||
        !mapping.answers ||
        mapping.answers.length === 0 ||
        mapping.questionStartRow === null
      ) {
        return false;
      }
    }
  }

  return !allSheetsIgnored;
};
