export function convertSnakeToCapitalized(str: string) {
  return str
    .split("_") // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words with spaces
}

/**
 * Converts a given string from snake_case to Title Case, capitalizing the first letter
 * of each word except for common conjunctions and prepositions (e.g., "of", "for")
 * unless they are the first word in the string.
 *
 * @param str - The input string in snake_case format.
 * @returns A string converted to Title Case format.
 */
export const convertSnakeToTitleCase = (str: string) => {
  const exceptions = new Set([
    "of",
    "for",
    "and",
    "in",
    "on",
    "at",
    "by",
    "to",
    "an",
    "a",
    "the",
  ]);

  return str
    .replace(/_/g, " ")
    .split(" ")
    .map((word, index) => {
      if (index === 0 || !exceptions.has(word.toLowerCase())) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.toLowerCase();
    })
    .join(" ");
};
