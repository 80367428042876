import ZaniaIcon from "@/shared/icons/zania";
import { MovingBorderComponent } from "../moving-border";

interface ZaniaLogoLoaderProps {
  loadingText?: string;
}

const ZaniaLogoLoader = () => {
  return (
    <div className="w-screen h-screen flex items-center justify-center bg-white">
      <MovingBorderComponent
        as={"div"}
        borderRadius="48px"
        borderClassName="bg-gradient-to-b from-[rgb(255,255,255)] via-[rgb(251,153,185)] to-[rgb(80,37,123)]"
        containerClassName="w-[10rem]  h-[10rem] zaniaLoader shadow-2xl shadow-red-200 "
        className="bg-white dark:bg-slate-900 border-neutral-200 dark:border-slate-800"
      >
        <ZaniaIcon height={50} />
      </MovingBorderComponent>
    </div>
  );
};

export default ZaniaLogoLoader;
