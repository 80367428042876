import { getSignedUrl } from "@/shared/requests/get-signed-url";

// Before rendering source, check and refresh URL if needed
export const checkAndRefreshUrl = async (url: string): Promise<string> => {
    try {
      const expiryDate = new URL(url).searchParams.get('se');
      if (expiryDate) {
        const expiry = new Date(expiryDate);
        const currentTime = new Date();
        const diff = expiry.getTime() - currentTime.getTime();
        
        // Refresh if URL is expired
        if (diff < 0) {
          const signedUrl = await getSignedUrl({
            stale_urls: [url],
            max_age: 86400,
          });
          return signedUrl[0];
        }
      }
      return url;
    } catch (error) {
      console.error('Error refreshing URL:', error);
      return url;
    }
  };