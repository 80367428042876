export const getTagAndRiskString = (data: string) => {
  const regex = /^([A-Z]{2}\.[A-Z]{2}-\d{2}): (.+)$/;
  const match = data.match(regex);
  if (match) {
    return {
      tag: match[1],
      riskString: match[2],
    };
  }
  return {
    tag: null,
    riskString: null,
  };
};

export const getTagColor = (tag: string) => {
  const type = tag.slice(0, 2);
  switch (type) {
    case "GV":
      return "bg-[#F9F49D]";
    case "ID":
      return "bg-[#4BB2E0]";
    case "PR":
      return "bg-[#9292EA]";
    case "DE":
      return "bg-[#FAB746]";
    case "DT": // Default to DE (Detect)
      return "bg-[#FAB746]";
    case "RS":
      return "bg-[#F97367]";
    case "RP": // Default to RS (Respond)
      return "bg-[#F97367]";
    case "RC":
      return "bg-[#7DF49F]";
  }
};
