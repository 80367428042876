export enum ROUTES {
  AGENT = "agent",
  SUCCESS = "success",
  DASHBOARD = "dashboard",
  WORKFLOWS = "workflows",
  INTEGRATIONS = "integrations",
  KNOWLEDGE_BASE = "knowledge-base",
  TEAM = "team",
  LOGIN = "login",
  SIGNUP = "signup",
  AUTH = "auth",
  SETTINGS = "settings",
  AGENT_SESSIONS = "sessions",
}

export enum AGENT_ROUTES {
  REVIEW_RESPONSES = "review",
  MAP_EXCEL = "map-excel",
  INTERMEDIATE = "intermediate",
  SUCCESS = "success",
}

export enum AGENT_TASK_ROUTES {
  QUESTIONNAIRE = "questionnaire",
  RISK_ASSESSMENT = "risk-assessment",
  DORA_ASSESSMENT = "gap-assessment",
}

export enum SETTINGS_ROUTES {
  PROFILE = "profile",
  NOTIFICATIONS = "notifications",
  SECURITY = "security",
  BILLING = "billing",
  INTEGRATIONS = "integrations",
  TEAM = "team",
  INVITE = "invite",
  LOGOUT = "logout",
}

export enum TEAM_ROUTES {
  INVITE = "invite",
  REMOVE_MEMBER = "remove/:memberId",
  UPDATE_MEMBER = "update/:memberId",
  TRANSFER_OWNER = "transfer/:member",
}
